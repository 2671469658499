.header {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 2em;
  place-items: center;
  height: 25vh;
}

.header > h1 {
  display: inline-block;
  justify-self: start;
  font-size: 4em;
}

.header-photo {
  display: inline-block;
  justify-self: end;
  height: 8em;
  width: 8em;
  border-radius: 50%;
  border: 2px solid var(--color-bluegray-600);
}
