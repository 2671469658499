.nav {
  text-align: right;
  border-right: 2px solid var(--color-bluegray-200);
}

.nav-link {
  padding: 10px 20px 10px 0px;
  text-decoration: none;
  color: inherit;
  display: block;
  font-size: 1.5em;
  line-height: 1.5em;
}

.active {
  background-color: var(--color-bluegray-300);
  border-right: 4px solid var(--color-highlight);
}
